@import "./../../common/Fonts.css";

.options-button {
    font-family: Raleway Medium;
    font-size: 14px;
    background-color: white;
    border: 1px solid #333;
    width: fit-content;
    padding: 2px 8px;
    border-radius: 50px;
    display: inline-block;
    margin: 15px 0 0 10px;
}

.options-button:hover {
    cursor: pointer;
}

.selected {
    background-color: #F1FFBB;
}