@import "./../../common/Fonts.css";

.wineItem {
    border-bottom: 1px solid #333;
    justify-content: space-between;
    padding: 14px;
}

.title {
    font-family: Raleway Extrabold;
    font-size: 23px;
    padding-bottom: 7px;
}

.subtitleContainer {
    flex-direction: row;
}

.subtitle1 {
    font-family: Raleway Medium;
    font-size: 16px;
    margin-right: 6px;
}

.subtitle2 {
    font-family: Raleway Light;
    font-size: 16px;
    margin-left: 6px;
}

.subtitle3 {
    font-family: Raleway Light;
    font-size: 14px;
    color: #858585;
    margin-bottom: 10px;
}

.rating-container {
    align-items: center;
    margin-top: 8px;
}

.rating-container span {
    font-family: Raleway Extrabold;
    font-size: 20px;
    position: relative;
    top: -4px;
    padding-left: 5px;
}