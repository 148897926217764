@font-face {
    font-family: Raleway Light;
    src: url(../../../common/assets/fonts/Raleway-Light.ttf);
}

@font-face {
    font-family: Raleway Medium;
    src: url(../../../common/assets/fonts/Raleway-Medium.ttf);
}

@font-face {
    font-family: Raleway Extrabold;
    src: url(../../../common/assets/fonts/Raleway-ExtraBold.ttf);
}