@import "./../../common/Fonts.css";

body {
    background-color: #F0F9FF;
    margin: 0;
    font-family: Raleway Medium;

}

#container {
    background-color: #F0F9FF;
    max-width: 500px;
    width: 100vw;
    padding-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
}

#profile-img-container {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-bottom: #333 solid 2px;
    background-color: #D9E8F1;
}

#profile-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#subview-container {
    position: relative;
    top: -80px;
    width: 100%;
    text-align: center;
}

#qr-container {
    background-color: #D9E8F1;
    width: fit-content;
    border-radius: 10px;
    border-width: 2px;
    border-color: #333;
    border-style: solid;
    align-items: center;
    justify-content: center;
    padding: 10px;
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 7px;
}

#profile-detail-container {
    border-bottom: 2px solid #333;
    padding-bottom: 20px;
    margin-bottom: -80px;
}

#profile-detail-container h2 {
    font-family: Raleway Extrabold;
    margin-bottom: 10px;
}

#joined-date {
    border: 1px solid #333;
    border-radius: 15px;
    padding: 4px 15px 4px 15px;
    width: fit-content;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #F1FFBB;
    font-size: 14px;
    margin-bottom: 20px;
    font-family: Raleway Medium;
}

#description {
    font-family: Raleway Medium;
    font-size: 16px;
}

#join-text-container {
    position: fixed;
    top: 90vh;
    left: 0;
    width: 100vw;
}

#join-text {
    width: 90vw;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 0.5em;
    border: 1px solid #333;
    text-align: center;
    font-family: Raleway Medium;
    display: block;
}

#join-text:hover {
    background-color: #F1FFBB;
    cursor: pointer;
}