@import "./../../common/Fonts.css";

#user-not-found {
    margin: auto;
    text-align: center;
    color: #636363;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
}

#user-not-found h1 {
    font-family: Raleway Medium;
}

#user-not-found p {
    font-family: Raleway Light;
}