@import "./../../common/Fonts.css";

#statistics {
    font-family: Raleway Medium;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #333;
}

#num-wine {
    border-right: 0.1em solid #333;
    flex-grow: 1;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

#num-wine div:first-child {
    font-size: 30px;
    font-family: Raleway Medium;
    position: relative;
    top: -5px;
}

#avg-rating {
    flex-grow: 1;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

#rating {
    font-size: 30px;
    font-family: Raleway Medium;
}

#rating span {
    margin-left: 6px;
    position: relative;
    top: -5px;
}